import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import HeaderMeta from '../components/HeaderMeta';
import { track } from '../analytics';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  TitleBox,
  SubHeadlineBox,
} from "@minware/ui-minware";

const header = {
  title: `Page not found | minware`,
  description: `The page you are looking for was not found, or you are not authorized to see it.`,
}


const NotFoundPage = () => {
  // Keep these events in sync with how 404 pages are tracked in the app
  useEffect(() => {
    track('Error - Page Not Found', {
      url: window.location.href,
    });
    track('Error Page Shown', {
      ...header
    })
  }, [])

  return (
    <Layout desktopBgImageName="none">
      <HeaderMeta header={header}/>
      <div>
        <ResponsiveMaxWidthFrame maxWidth="500px">
            <SectionGroup>
              <PageNameBox>Not Found</PageNameBox>
              <TitleBox>
                This page doesn't exist, or you're not authorized to see it.
              </TitleBox>
              <SubHeadlineBox centered={true}>
                Try logging in, or contact support@minware.com if problems continue.
              </SubHeadlineBox>
            </SectionGroup>
        </ResponsiveMaxWidthFrame>
      </div>
    </Layout>
  );
  }

export default NotFoundPage
